import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Power Clean 5×2\\@90% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`21-Thrusters (95/65)`}</p>
    <p>{`42-Double Unders`}</p>
    <p>{`21-Wall Balls (20/14)`}</p>
    <p>{`42-Double Unders`}</p>
    <p>{`15-Thrusters`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`15-Wall Balls`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`9-Thrusters`}</p>
    <p>{`18-Double Unders`}</p>
    <p>{`9-Wall Balls`}</p>
    <p>{`18-Double Unders`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Next Saturday, July 18th, is our Ville in-house competition!  Sign up
with the link provided in our latest email or just email us and we’ll
sign you up.  RX and Scaled divisions so this competition is for all of
our members.  Arrive by 8:30am, first wod to start at 9:00.  `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      